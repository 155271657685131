export default function getGoogleAnalyticsSettings () {
  const settings = {}

  const rawIds = process.env.VUE_APP_GA_IDS
  if (!rawIds || rawIds === '[]') return {}
  const ids = rawIds.replace(/\s/g, '').split(',')

  if (ids.length) {
    settings.config = { id: ids[0] }
    if (ids.length > 1) {
      ids.shift()
      settings.includes = ids.map((id) => ({ id }))
    }
  }
  return settings
}
