<template>
  <div id="app">
    <div class="el-section el-centered eu-align-items_center" v-if="isLoading && !hasError">
      <emd-pagarme-loader :color="'secondary'" class="fade-in"/>
    </div>
    <global-error :theme="theme" v-if="!isLoading && hasError" :code="errorCode" />
    <template>
      <router-view />
    </template>
  </div>
</template>
<script>
import { GlobalError, EmdPagarmeLoader } from 'emerald-vue/src/components'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    EmdPagarmeLoader,
    GlobalError
  },
  computed: {
    ...mapGetters({
      isLoading: 'globalLoading',
      hasError: 'globalError',
      appVersion: 'appVersion'
    }),
    theme () {
      return process.env.VUE_APP_THEME
    },
    errorCode () {
      return this.$store.getters.globalErrorCode || 500
    }
  },
  mounted () {
    var meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
