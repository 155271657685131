import i18n from '@/i18n'

const catchError = function (error, context) {
  if (error.status === 401) {
    context.dispatch('DELETE_AUTHORIZATION')
    window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
  } else if (error.status === 412) {
    if (!error.body) error.body = {}
    const messages = [{ message: i18n.t('alert.password-error') }]
    error.body.errors ? error.body.errors.push(messages) : error.body.errors = messages
  } else if (error.status === 400 && error.body.notifications) {
    const notifications = Object.values(error.body.notifications)
    const messages = notifications.map(message => { return { message: message[0] } })
    error.body.errors ? error.body.errors.push(messages) : error.body.errors = messages
  } else if (error.status !== 400 && error.status !== 422) {
    context.dispatch('TOGGLE_GLOBAL_ERROR', { status: true, code: error.status })
  }
  if (window.newrelic) { window.newrelic.noticeError(error.message) }
  return Promise.reject(error)
}

export default catchError
