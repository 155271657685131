import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'
import { init as initFS } from '@fullstory/browser'
import getGoogleAnalyticsSettings from './utils/googleAnalytics'
// IMPORT EMERALD GLOBALY
import 'emerald-workbench/dist/emerald.pagarme.min.css'

import shadow from 'vue-shadow-dom'

import { start } from 'single-spa'

Vue.config.productionTip = false

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  const googleAnalyticsSettings = getGoogleAnalyticsSettings()
  Vue.use(VueGtag, googleAnalyticsSettings, router)

  initFS({ orgId: 'KQA5' })
}
Vue.use(shadow)
start()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
