import Vue from 'vue'
import Vuex from 'vuex'
import ProfileCore from 'profile-js'
import { log as logFS, identify } from '@fullstory/browser'
import { version } from '@/../package.json'

import { catchError } from './utils'

const coreParams = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}

export const ProfileInstance = new ProfileCore(coreParams)

Vue.use(Vuex)

export const env = ProfileInstance.envAcronym

export default new Vuex.Store({
  state: {
    profile: undefined,
    globalLoading: false,
    globalError: false,
    globalErrorCode: '',
    appVersion: version
  },
  mutations: {
    setProfile (state, payload) {
      state.profile = payload
    },
    identifyFs (state) {
      const userVars = {
        id: state.profile.user.id,
        displayName: `${state.profile.user.first_name} ${state.profile.user.last_name}`,
        email: state.profile.user.email,
        merchantId_str: state.profile.merchant ? state.profile.merchant.id : null,
        merchantName_str: state.profile.merchant ? state.profile.merchant.name : null,
        userType_str: state.profile.merchant ? state.profile.merchant.type.key : null,
        appVersion_str: version
      }
      identify(state.profile.user.email, userVars)
    },
    toggleGlobalLoading (state, status) {
      state.globalLoading = status
    },
    toggleGlobalError (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
      if (status && code !== 404 && code !== 403 && ['production'].includes(process.env.VUE_APP_CORE_ENV)) logFS('error', 'Error: redirected to screen 500')
    }
  },
  actions: {
    GET_AUTHORIZATION (context, params = {}) {
      return ProfileInstance.authorization.get(params, 'onboarding', process.env.VUE_APP_PROFILE_URL)
        .then(res => {
          if (window.newrelic) {
            window.newrelic.interaction()
              .setAttribute('id', res.data.user.id)
              .setAttribute('username', `${res.data.user.first_name} ${res.data.user.last_name}`)
              .setAttribute('email', res.data.user.email)
              .setAttribute('merchant_id', res.data.merchant ? res.data.merchant.name : null)
          }
          return res
        })
        .then((res) => {
          if (res.data.merchant) {
            context.commit('setProfile', res.data)
            if (['production'].includes(process.env.VUE_APP_CORE_ENV)) context.commit('identifyFs')
          }
          return Promise.resolve(res.data)
        })
        .catch((err) => {
          context.commit('toggleGlobalLoading', false)
          return catchError(err, context)
        })
    },
    TOGGLE_GLOBAL_LOADING ({ commit }, status) {
      commit('toggleGlobalLoading', status)
    },
    TOGGLE_GLOBAL_ERROR ({ commit }, errorInfo) {
      commit('toggleGlobalError', errorInfo)
    },
    SET_ERROR (context, error) {
      catchError(error, context)
    },
    DELETE_AUTHORIZATION (context, logout = false) {
      return ProfileInstance.authorization.deleteCookies('onboarding', logout)
    }
  },
  getters: {
    profile (state) {
      return state.profile
    },
    globalLoading (state) {
      return state.globalLoading
    },
    globalError (state) {
      return state.globalError
    },
    appVersion (state) {
      return state.appVersion
    },
    globalErrorCode (state) {
      return state.globalErrorCode
    }
  }
})
