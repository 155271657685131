/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Motherboard from './components/project/templates/Motherboard.vue'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: '/:merchant_id',
      name: 'merchant',
      component: Motherboard,
      beforeEnter: (to, from, next) => {
        var { merchant_id } = to.params
        const params = { merchant_id }
        if (merchant_id.includes('merch_')) {
          store.dispatch('GET_AUTHORIZATION', params).then((res) => {
            store.dispatch('TOGGLE_GLOBAL_LOADING', false)
            if (res.merchant) next({ path: `/${params.merchant_id}/${res.merchant.account.id}/` })
          })
        } else {
          next({ name: 'default' })
        }
      }
    },
    {
      path: '/:merchant_id/:account_id',
      component: Motherboard,
      beforeEnter: (to, from, next) => {
        const { merchant_id, account_id } = to.params
        const params = { merchant_id, account_id }
        const profile = store.getters.profile
        if (!merchant_id.includes('merch_') || !account_id.includes('acc_')) {
          next({ name: 'default' })
        } else if (!profile) {
          store.dispatch('GET_AUTHORIZATION', params).then(() => {
            store.dispatch('TOGGLE_GLOBAL_LOADING', false)
            next()
          })
        } else {
          next()
        }
      },
      children: [
        {
          path: '',
          name: 'motherboard',
          redirect: { name: 'onboarding' }
        },
        {
          path: '/',
          name: 'onboarding',
          component: () => import(/* webpackChunkName: "onboarding" */ './views/Onboarding.vue')
        }
      ]
    },
    {
      path: '/',
      name: 'default',
      component: Motherboard,
      beforeEnter: (to, from, next) => {
        const profile = store.getters.profile
        if (!profile) {
          store.dispatch('GET_AUTHORIZATION', {}).then((res) => {
            if (res.merchant) {
              store.dispatch('TOGGLE_GLOBAL_LOADING', false)
              next({ path: `/${res.merchant.id}/${res.merchant.account.id}` })
            }
          })
        } else {
          next()
        }
      }
    },
    {
      path: '*',
      redirect: { name: 'default' }
    }
  ]
})
