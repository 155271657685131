<template>
  <div>
    <transition :name="transitionName" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Motherboard',
  data () {
    return {
      transitionName: 'slide-right'
    }
  },
  watch: {
    $route (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      // closes appMenu on route change
      if (to !== from && this.appMenuOpen) this.appMenuOpen = false
    }
  }
}
</script>

<style>
#atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
  border: none;
  box-shadow: none;
  border-radius: 4px 0 0 0;
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 11px;
  padding: 6px 12px;
  z-index: 0;
}
._mode_dark #atlwdg-trigger {
  background: var(--brand--de--primary-color--base);
  color: #272829 !important;
}
._mode_light #atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 500ms;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translateX(calc(2em - 6px));
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translateX(calc(-2em - 6px));
}
</style>
